import loadScript from 'load-script';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import FadeLoading from '../assets/img/anim_loading.gif';
import { api } from '../service/api';
import MustLight from '../assets/img/header-light.png';

declare var $: any;

declare global {
  interface Window {
    WMKS: any;
  }
}

const Container = styled.div`
  background-color: black;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const LoadingImage = styled.img`
  height: 300px;
`;

const MessageContainer = styled.div<{ bgColor: string }>`
  background-color: ${(props) => props.bgColor};
  padding: 0.5rem;
  display: none;
  text-align: center;
`;

const MessageText = styled.span<{ color: string }>`
  color: ${(props) => props.color};
  text-align: center;
  font-family: sans-serif;
  font-weight: bold;
`;

const CommandsContainer = styled.div`
  background-color: #27272a;
  display: flex !important;
  gap: 10px;
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  background-color: #27272a;
  padding: 0.5rem 1rem;
`;

const CommandButton = styled.button`
  background-color: #3b82f6;
  border: 1px solid #3b82f6;
  border-radius: 0.5rem;
  color: white;
  text-align: center;
  font-family: sans-serif;
  font-weight: bold;
  text-transform: capitalize;
  padding: 0.5rem 1rem;
  cursor: pointer;
`;

const ConsoleContainer = styled.div`
  position: relative;
  background-color: black;
`;

const Console: React.FC = () => {
  const [link, setLink] = useState<string | null>(null);
  const [wmksInstance, setWmksInstance] = useState<any>(null);

  // Carrega o link do console
  useEffect(() => {
    const id = localStorage.getItem('server_detail');
    if (!id) {
      window.history.back();
      return;
    }

    const fetchConsoleLink = async () => {
      try {
        const response = await api.get(`/console/${id}/`);
        setLink(response.data.url);
      } catch (error) {
        console.error('Erro ao conectar no servidor:', error);
        window.history.back();
      } finally {
        console.log('Console loaded');
      }
    };

    const timer = setTimeout(() => {
      fetchConsoleLink();
    }, 5000);

    return () => clearTimeout(timer);
  }, []); // Dependências vazias garantem que só seja chamado uma vez

  useEffect(() => {
    const adjustConsoleSize = () => {
      const width = window.innerWidth;
      const height = window.innerHeight - 102;
      $('#console')
        .css('width', width)
        .css('height', height)
        .css('outline', 'none');
    };
  
    if (!link) return;
  
    const loadScripts = async () => {
      try {
        await new Promise<void>((resolve, reject) => {
          loadScript('https://code.jquery.com/jquery-1.8.3.min.js', (err) => {
            if (err) return reject('Erro ao carregar o jQuery');
            console.log('jQuery carregado com sucesso');
            resolve();
          });
        });
  
        await new Promise<void>((resolve, reject) => {
          loadScript('https://code.jquery.com/ui/1.8.16/jquery-ui.min.js', (err) => {
            if (err) return reject('Erro ao carregar o jQuery UI');
            console.log('jQuery UI carregado com sucesso');
            resolve();
          });
        });
  
        await new Promise<void>((resolve, reject) => {
          loadScript('https://cdn.jsdelivr.net/npm/vmware-wmks@1.0.0/wmks.min.js', (err) => {
            if (err) return reject('Erro ao carregar o WMKS');
            console.log('WMKS carregado com sucesso');
            resolve();
          });
        });
  
        if (window.WMKS) {
          const instance = window.WMKS.createWMKS('console', {
            useNativePixels: true,
            enableUint8Utf8: true,
          });
          setWmksInstance(instance);
  
          adjustConsoleSize();
  
          instance.register('connectionstatechange', (event: any, data: any) => {
            console.log('Connection state change:', data.state);
  
            if (data.state === window.WMKS.CONST.ConnectionState.CONNECTING) {
              console.log('Connecting...');
              $('#connecting').show();
            }
            if (data.state === window.WMKS.CONST.ConnectionState.CONNECTED) {
              console.log('Connected');
              $('#connecting').hide();
              $('#connected').show();
              $('#commands').show();
            }
            if (data.state === window.WMKS.CONST.ConnectionState.DISCONNECTED) {
              console.log('Disconnected');
              $('#connecting').hide();
              $('#connected').hide();
              $('#disconnected').show();
            }
          });
  
          instance.register('error', (event: any) => {
            console.error('WMKS error:', event);
          });
  
          $('#cad').click(() => {
            instance.sendCAD();
          });
  
          $('#fsn').click(() => {
            instance.enterFullScreen();
          });
  
          try {
            instance.connect(link);
          } catch (error) {
            console.error('Erro ao conectar ao WMKS:', error);
          }
        } else {
          console.error('WMKS library not loaded');
        }
      } catch (error) {
        console.error('Erro ao carregar scripts:', error);
      }
    };
  
    loadScripts();
  
    window.addEventListener('resize', adjustConsoleSize);
  
    return () => {
      window.removeEventListener('resize', adjustConsoleSize);
      if (wmksInstance) {
        try {
          wmksInstance.disconnect();
          wmksInstance.destroy();
        } catch (error) {
          console.error('Erro ao desconectar WMKS:', error);
        }
      }
    };
  }, [link]); 

  return (
    <>
      {!link && (
        <Container>
          <LoadingImage src={FadeLoading} alt="Loading" />
        </Container>
      )}
      {link && (
        <div>
          <MessageContainer id="connecting" bgColor="#164e63">
            <MessageText color="#22d3ee">Conectando...</MessageText>
          </MessageContainer>

          <MessageContainer id="connected" bgColor="#065f46">
            <MessageText color="#34d399">Conectado com sucesso!</MessageText>
          </MessageContainer>

          <MessageContainer id="disconnected" bgColor="#7f1d1d">
            <MessageText color="#f87171">Desconectado!</MessageText>
          </MessageContainer>

          <HeaderContainer>
            <img src={MustLight} alt="Must Light" style={{ height: '50px' }} />
            <CommandsContainer id="commands">
              <CommandButton id="cad">Ctrl+Alt+Del</CommandButton>
              <CommandButton id="fsn">Tela Cheia</CommandButton>
            </CommandsContainer>
          </HeaderContainer>

          <ConsoleContainer>
            <div id="console" style={{ backgroundColor: 'black', width: '100%' }}></div>
          </ConsoleContainer>
        </div>
      )}
    </>
  );
};

export default Console;