import { AiOutlineGroup, AiOutlineLock, AiOutlineMail, AiOutlineUser } from 'react-icons/ai';
import { Data, GroupName, Label, ReviewContainer, ReviewItem, ReviewWrapper, Title } from './style';
interface UserDataReviewProps {
    userData: {
        first_name: string;
        last_name: string;
        username: string;
        email: string;
        password: string;
        groups: number[];
    };
}

export const UserDataReview: React.FC<UserDataReviewProps> = ({ userData }) => {
    return (
        <ReviewWrapper>
            <Title>Revisão de dados</Title>
            <ReviewContainer>
                <ReviewItem>
                    <AiOutlineUser />
                    <Label>Nome:</Label> <Data>{userData?.first_name} {userData?.last_name}</Data>
                </ReviewItem>
                <ReviewItem>
                    <AiOutlineUser />
                    <Label>Usuário:</Label> <Data>{userData?.username}</Data>
                </ReviewItem>
                <ReviewItem>
                    <AiOutlineMail />
                    <Label>Email:</Label> <Data>{userData?.email}</Data>
                </ReviewItem>
                <ReviewItem>
                    <AiOutlineLock />
                    <Label>Senha:</Label> <Data>{'*'.repeat(userData?.password?.length)}</Data>
                </ReviewItem>
                <ReviewItem>
                    <AiOutlineGroup />
                    <Label>Grupos:</Label>
                    {userData?.groups.map((groupId) => (
                        <GroupName key={groupId}>{groupId}</GroupName>
                    ))}
                </ReviewItem>
            </ReviewContainer>
        </ReviewWrapper>
    );
};