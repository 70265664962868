import { transparentize } from 'polished';
import styled from "styled-components";



const COLORS = {
    red: '#e74c3c',
    primary: '#1570EF',
    secondary: '#e74c3c',
    foreground: '#274060'
}

const THEME = {
    primary: {
        bg: '#1570EF',
        color: '#FFFFFF',
        onHover: `
        box-shadow: 0 3px 6px rgba(0,0,0,.2);
        bg: '#274060';
    `,
        disabled: {
            color: '#fff',
            bg: transparentize(0.44, COLORS.primary)
        }
    },
    secondary: {
        bg: '#e74c3c',
        color: '#fff',
        onHover: `
        box-shadow: 0 3px 6px rgba(0,0,0,.2);
    `,
        disabled: {
            color: '#646464',
            bg: transparentize(0.44, COLORS.secondary)
        }
    },
    foreground: {
        bg: 'transparent',
        color: '#e74c3c',
        onHover: `
        box-shadow: 0 3px 6px rgba(0,0,0,.2);
    `,
        disabled: {
            color: '#646464',
            bg: transparentize(0.44, COLORS.secondary)
        }
    }
}

export interface ButtonTheme {
    width?: any;
    height?: any;
    variant: 'primary' | 'secondary' | 'foreground'
    radius?: any;
}

export const Body = styled.button`
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    padding: 0;
    text-align: center;
    background-color: transparent;
    border: none;

    &:disabled {
        cursor: not-allowed !important;
        opacity: 0.8;
    }
`


export const Wrapper = styled.button<ButtonTheme & { buttonType?: "text" | "icon" | "icon-text" }>`
    display: flex;
    justify-content: center;;
    text-align: center;
    width: 100%;
    height: 100%;
    border: 1px solid ${p => THEME[p.variant].bg};
    border-radius: ${({ radius }: ButtonTheme) => radius || '5px'};
    color: ${p => THEME[p.variant].color};
    align-items: center; 
    background-color: ${p => THEME[p.variant].bg};
    padding: 6px 12px;
    gap: 6px;
    transition: all 0.2s ease-in-out;  
    cursor: pointer;
    

  .icon {
    color: ${p => p.variant === 'secondary' ? '#FFFFFF' : THEME[p.variant].color};
  }

  &:hover,
  &:focus {
    ${p => THEME[p.variant].onHover};
  }

  &:disabled {
    background-color: ${p => THEME[p.variant].disabled.bg};
    color: ${p => THEME[p.variant].disabled.color};
    cursor: not-allowed;
    pointer-events: opacity;
    border-color: transparent;
  }
  
`
export const TextArea = styled.span`
    display: flex;
    font-size: 18px;
    align-items: flex-end;
    justify-content: center;
`