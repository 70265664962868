
import { jwtDecode } from 'jwt-decode';


interface SidebarItem {
  path: string;
  profiles?: string[];
  subItems?: SidebarItem[];
}

export const isDefault = () => {
  const token = localStorage.getItem('accessToken');
  if (token) {
    const jwt_parsed = JSON.parse(atob(token.split('.')[1]));
    console.log(jwt_parsed);
    if (jwt_parsed['profile'] === 'padrão') {
      return true;
    }
  }
  return false
}


interface TokenPayload {
  profile: string;
  [key: string]: any;
}

export const getUserProfile = (): string => {
  const token = localStorage.getItem("accessToken");
  if (token) {
    try {
      const decoded: TokenPayload = jwtDecode(token);
      console.log('aaaa', decoded.profile)
      return decoded.profile || "";
    } catch (error) {
      console.error("Erro ao decodificar o token:", error);
      return "";
    }
  }
  return "";
};

export const hasPermission = (item: SidebarItem, userProfile: string): boolean => {
  if (!item.profiles || item.profiles.length === 0) {
    return true;
  }
  const hasDirectPermission = item.profiles.includes(userProfile);
  if (item.subItems) {
    const hasChildPermission = item.subItems.some(subItem =>
      hasPermission(subItem, userProfile)
    );
    return hasDirectPermission || hasChildPermission;
  }
  return hasDirectPermission;
};

export const isFirstAccess = () => {
  const token = localStorage.getItem('accessToken');
  if (token) {
    const parsed: any = jwtDecode(token);
    if (parsed['first_access']) {
      return true;
    }
  }
  return false;
}

export const getUserId = () => {
  const token = localStorage.getItem('accessToken');
  if (token) {
    const parsed: any = jwtDecode(token);
    return parsed['user_id'];
  }
  return '';
}