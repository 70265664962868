import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 20px;
  padding: 20px 40px;
  height: calc(100vh - 20px);
  overflow: scroll;
`

export const Body = styled.div`
  display: flex;
  gap: 20px;
  background-color: white;
  padding: 20px 36px;
  flex-direction: column;
`

export const Title = styled.span` 
  font-weight: bold;
  padding-bottom: 8px;
  font-size: 16px;
`;


export const Text = styled.div`
  color: #aaa;
`

export const Value = styled.div`
    font-size: 40px;
` 